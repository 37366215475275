@import url("./font.css");
@import url("./variables.css");

* {
    margin: 0;
    padding-left: 0;
    box-sizing: border-box;
    font-family: "Inter";
}

html,
body {
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: right;
}
